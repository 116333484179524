@import "../assets/styles/main";

.Credits {
    position: absolute;
    padding: 5px 10px;
    bottom: 20px;
    left: 20px;
    background-color: rgba(0,0,0,0.2);
    z-index: 257;
    display: flex;
}

.Logo {
    overflow: visible;
    float: left;
}

.Block {
    text-align: left;
    //transform: translate(-50px, 10px);
    font-size: small;
    color: white;
    overflow: visible;
    float: left;
}
